import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';



const transportData = [
  {
      "COMPANY": "LifeLine Ambulance Station 1 - HQ",
      "ADDRESS": "501 North Wenatchee Ave, Wenatchee WA 98801",
      "CAPABILITY": "EMS",
      "PHONE": "509-663-8091",
      "HOURS": "24hour Ambulance and Wheelchair Transport Request",
      "Population Served": null,
      "SERVICES": "ALS",
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "17 ambulances, five 4-wheel drive response units, and 2 wheelchair transport van",
      "latitude": 47.4310657,
      "longitude": -120.3161653
  },
  {
      "COMPANY": "LifeLine Ambulance Station 2",
      "ADDRESS": "230 Grant Road Suite B-6, East Wenatchee, WA 98802",
      "CAPABILITY": "EMS",
      "PHONE": "509-663-8091",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": "ALS",
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.4054085,
      "longitude": -120.2853646
  },
  {
      "COMPANY": "LifeLine Ambulance Station 3",
      "ADDRESS": "913 Koala Dr., Omak WA 98841",
      "CAPABILITY": "EMS",
      "PHONE": "509-422-4212",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": "ALS",
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 48.4223542,
      "longitude": -119.5047995
  },
  {
      "COMPANY": "LifeLine Ambulance Station 4",
      "ADDRESS": "1311 Ironwood Street, Oroville WA 98844",
      "CAPABILITY": "EMS",
      "PHONE": "509-422-4212",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": "ALS",
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 48.9384138,
      "longitude": -119.4379388
  },
  {
      "COMPANY": "LifeLine Ambulance Station 5",
      "ADDRESS": "21 5th Street E, Tonasket, WA 98855",
      "CAPABILITY": "EMS",
      "PHONE": "509-422-4212",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": "ALS",
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 48.70352459999999,
      "longitude": -119.439295
  },
  {
      "COMPANY": "LifeLine Ambulance Station 6",
      "ADDRESS": "1025 Arlington Drive, Moses Lake, WA 98837",
      "CAPABILITY": "EMS",
      "PHONE": "509-663-8091",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": "ALS",
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.1736144,
      "longitude": -119.3207607
  },
  {
      "COMPANY": "American Medical Response",
      "ADDRESS": "116 W. 4th AvenueMoses Lake, WA 98837",
      "CAPABILITY": "EMS",
      "PHONE": "509-765-2155",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.1290195,
      "longitude": -119.2780806
  },
  {
      "COMPANY": "Coulee City Fire Department",
      "ADDRESS": "PO Box 398Coulee City, WA 99115",
      "CAPABILITY": "EMS",
      "PHONE": "509-632-5331",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "couleecityfire@hotmail.com",
      "latitude": 47.6112561,
      "longitude": -119.2922499
  },
  {
      "COMPANY": "Coulee Dam Fire Department",
      "ADDRESS": "Coulee Dam, WA 99116",
      "CAPABILITY": "EMS",
      "PHONE": "509-633-1233",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.9699945,
      "longitude": -118.9786821
  },
  {
      "COMPANY": "Ephrata Fire Department",
      "ADDRESS": "800 A Street SEEphrata, WA 98823",
      "CAPABILITY": "EMS",
      "PHONE": "509-754-4666",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "www.ephrata.org",
      "latitude": 47.3125714,
      "longitude": -119.5539077
  },
  {
      "COMPANY": "Grand Coulee Volunteer Fire Department and Ambulance",
      "ADDRESS": "PO Box 180Grand Coulee, WA 99133",
      "CAPABILITY": "EMS",
      "PHONE": "509-633-1105",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": "4 EMRs",
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "gcfire@gccitywa.org",
      "latitude": 47.9391662,
      "longitude": -119.0120921
  },
  {
      "COMPANY": "City of Grand Coulee Volunteer Fire Department (GCVFD)",
      "ADDRESS": "205 Spokane Way, Grand Coulee, WA. 99133",
      "CAPABILITY": "EMS",
      "PHONE": "(509) 633 – 1150",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.9392797,
      "longitude": -119.003584
  },
  {
      "COMPANY": "Grant County Fire District 3",
      "ADDRESS": "PO Box 565Quincy, WA 98848",
      "CAPABILITY": "EMS",
      "PHONE": "509-787-2713",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": "Quincy, Winchester, Block 71, Low Gap, George, Sunland, and Crescent Bar",
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "www.gcfd3.net",
      "latitude": 47.2342997,
      "longitude": -119.8525504
  },
  {
      "COMPANY": "Grant County Fire District 4",
      "ADDRESS": "PO Box 368Warden, WA 98857",
      "CAPABILITY": "EMS",
      "PHONE": "509-349-2471",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 46.9676426,
      "longitude": -119.0397305
  },
  {
      "COMPANY": "Grant County Fire District 5",
      "ADDRESS": "11058 Nelson RoadMoses Lake, WA 98837",
      "CAPABILITY": "EMS",
      "PHONE": "509-765-3175",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "www.gcfd5.org",
      "latitude": 47.1169641,
      "longitude": -119.256046
  },
  {
      "COMPANY": "Grant County Fire District 6",
      "ADDRESS": "PO Box 132Hartline, WA 99135",
      "CAPABILITY": "EMS",
      "PHONE": "509-639-2522",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.6904251,
      "longitude": -119.1083534
  },
  {
      "COMPANY": "Grant County Fire District 7",
      "ADDRESS": "PO Box 1449Soap Lake, WA 98851",
      "CAPABILITY": "EMS",
      "PHONE": "509-246-0321",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.389307,
      "longitude": -119.490591
  },
  {
      "COMPANY": "Grant County Fire District 8",
      "ADDRESS": "PO Box 1728Mattawa, WA 99349",
      "CAPABILITY": "EMS",
      "PHONE": "509-932-4777",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 46.73791,
      "longitude": -119.9028234
  },
  {
      "COMPANY": "Grant County Fire District 10",
      "ADDRESS": "PO Box 220Royal City, WA 99357",
      "CAPABILITY": "EMS",
      "PHONE": "509-346-2658",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 46.90097009999999,
      "longitude": -119.6305912
  },
  {
      "COMPANY": "Grant County Fire District 12",
      "ADDRESS": "401 Railroad StreetWilson Creek, WA 98860",
      "CAPABILITY": "EMS",
      "PHONE": "509-750-5960",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.4221169,
      "longitude": -119.1213876
  },
  {
      "COMPANY": "Grant County Fire District 13",
      "ADDRESS": "PO Box 812Ephrata, WA 98823",
      "CAPABILITY": "EMS",
      "PHONE": "509-754-3276",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": "BLS Medical Aid\nRescue and Motor Vehicle Collisions\nIndustrial Accidents\nHAZMAT Awareness \nMutual Aid\nState Mobilization\nStructural and Wildland Fire Suppression",
      "SERVICE AREA": "Fire Dist. 13 services the areas in Grant County surrounding the city of Ephrata.  We cover 126 square miles and often respond inside the city of\nEphrata to provide mutual aid.  In addition we contract with the Rimrock Meadows community in Douglas County.  Our centrally located fire station is structured for the fastest available response.  We also respond to assist with large wildfires throughout the state that are to big for the originating agencies to handle (Referred to as State Mobilization).",
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "http://grant13firerescue.org",
      "latitude": 47.3176385,
      "longitude": -119.5536491
  },
  {
      "COMPANY": "Lifeline Ambulance Inc.",
      "ADDRESS": "PO Box 289 Wenatchee, WA 98807",
      "CAPABILITY": "EMS",
      "PHONE": "509-663-4602",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.41999999999999,
      "longitude": -120.31
  },
  {
      "COMPANY": "Moses Lake Fire Department",
      "ADDRESS": "PO Box 1579Moses Lake, WA 98837",
      "CAPABILITY": "EMS",
      "PHONE": "509-765-2204 |\n509-760-9726",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.1301417,
      "longitude": -119.2780771
  },
  {
      "COMPANY": "Protection1, LLC",
      "ADDRESS": "908 10th Avenue, SW (Physical location) | P.O. Box 1718 (Mailing address) Quincy, WA 98848",
      "CAPABILITY": "EMS",
      "PHONE": "509-787-3535",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.23067169999999,
      "longitude": -119.8680709
  },
  {
      "COMPANY": "Rural Metro Fire Department",
      "ADDRESS": "6997 Patton Blvd. NEMoses Lake, WA 98837",
      "CAPABILITY": "EMS",
      "PHONE": "509-762-5304",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": 47.1895578,
      "longitude": -119.3221772
  },
  {
      "COMPANY": "Columbia EMS",
      "ADDRESS": "908 10th Avenue, SW (Physical location) | P.O. Box 1718 (Mailing address) Quincy, WA 98848",
      "CAPABILITY": "EMS",
      "PHONE": "(509) 289-9872",
      "HOURS": "24/7",
      "Population Served": null,
      "SERVICES": "ALS",
      "SERVICE AREA": "Serving the City of Quincy and residents residing within the boundaries of Grant County Fire District #3 including George, Sunland and Crescent Bar. ",
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "ems@columbiaems.com",
      "latitude": 47.23067169999999,
      "longitude": -119.8680709
  },
  {
      "COMPANY": "People for People",
      "ADDRESS": null,
      "CAPABILITY": "Para-transit | Special Needs",
      "PHONE": "509-765-9249 ext. 555 |\n2-1-1 |\n877-211-5445 ",
      "HOURS": "8-5pm M-F",
      "Population Served": "Non-Medicaid | General Public | Adults 60+ | Low Income | Youth | Disabled | Veterans",
      "SERVICES": "Para-Transit |\nSpecial Needs transportation\nNorma Mendoza",
      "SERVICE AREA": "Grant, Adams, Lincold, Yakima counties",
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "Free",
      "NOTES": "Non-emergency\n2-business days scheduling needed (if available)\nDependent on availability of funding",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Spokane Paratransit",
      "ADDRESS": null,
      "CAPABILITY": "Para-transit",
      "PHONE": null,
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "Door-to-door bus service in and around Spokane that is accessible to wheelchair users",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Deer Park Dial-A-Ride",
      "ADDRESS": null,
      "CAPABILITY": null,
      "PHONE": null,
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "Door-to-door bus service in Deer Park that is accessible to wheelchair users",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Volunteer Services",
      "ADDRESS": null,
      "CAPABILITY": null,
      "PHONE": null,
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": null,
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": "Catholic Charities of Eastern Washington provides transportation services to low income seniors and people with disabilities",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Specialty Mobility Services (SMS)",
      "ADDRESS": "12615 East Mission, Suite 312, Spokane Valley, WA 99216",
      "CAPABILITY": "Taxi | Cabulance | Wheelchair",
      "PHONE": "1-509-534-9760",
      "HOURS": "24/7",
      "Population Served": "Medicaid Only ",
      "SERVICES": null,
      "SERVICE AREA": "Adams, Asotin, Ferry, Garfield, Grant, Lincoln, Pend Orielle, Spokane, Stevens, Whitman",
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "HCA Broker",
      "NOTES": "dispatch@specialmobility.org\nRequires provider one subscriber #\nCalls after 4pm transfer to call center\nSame day appointments based on availability",
      "latitude": 47.6722537,
      "longitude": -117.2348092
  },
  {
      "COMPANY": "Grant Transit Authority (GTA)",
      "ADDRESS": null,
      "CAPABILITY": null,
      "PHONE": "509-766-1688 |\n509-765-0898 ext 0",
      "HOURS": "7:05am-5:05pm",
      "Population Served": "Public Transportation ",
      "SERVICES": "Hospital Pick-up",
      "SERVICE AREA": "Grant County",
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "Free",
      "NOTES": "Saira Martinez - GTA Program Applications. \nContracts with People for People.\nMust call ahead to schedule pickup. Bus will not wait. No curb-curb service, ends at GTA hub",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "GTA Dial-A-Ride",
      "ADDRESS": null,
      "CAPABILITY": null,
      "PHONE": "509-765-0898",
      "HOURS": null,
      "Population Served": null,
      "SERVICES": null,
      "SERVICE AREA": "Grant County",
      "OXYGEN": null,
      "Pay Source": null,
      "NOTES": null,
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "MedStar Cabulance",
      "ADDRESS": null,
      "CAPABILITY": "Cabulance | Wheelchair | BLS",
      "PHONE": "877-512-6996",
      "HOURS": "24/7",
      "Population Served": "Medicare ",
      "SERVICES": "Hospital Pick-up",
      "SERVICE AREA": "Adams, Asotin, Ferry, Garfield, Grant, Lincoln, Pend Orielle, Spokane, Stevens, Whitman",
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "Medicare",
      "NOTES": "non-emergent ambulance\nCalls after 4pm transfers to call center for Med Star Cabulance who schedules for hospital discharges\nMedicare only if coverafe is provided in patient's insurance policy",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Pack's Delivery & Taxi",
      "ADDRESS": null,
      "CAPABILITY": "Taxi",
      "PHONE": "509-762-1234 | 509-762-1234",
      "HOURS": null,
      "Population Served": "General Public without mobility limitations",
      "SERVICES": "Hospital Pick-up",
      "SERVICE AREA": null,
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "Cash | Credit Card | Debit Card",
      "NOTES": "No assistance to door. \nNeed to be independent with mobility",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Non-Emergent Ambulance ",
      "ADDRESS": null,
      "CAPABILITY": "EMS | Cabulance",
      "PHONE": "509-760-9726",
      "HOURS": "Depending on availability",
      "Population Served": "Medicare Part B",
      "SERVICES": "Hospital Pick-up",
      "SERVICE AREA": null,
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "Medicare Part B",
      "NOTES": "Patients needing roundtrip transportation to a skilled nursing facilities following hospital admission or emergency care only if qualified.\nMust have original Medicare part B coverage. Patient pays 20% of approved amount after meeting deductible.",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Scabland Taxi Wheelchair Van",
      "ADDRESS": null,
      "CAPABILITY": "Wheelchair | Taxi  ",
      "PHONE": "509-762-4183",
      "HOURS": "Depending on availability",
      "Population Served": "All",
      "SERVICES": "Hospital Pick-up",
      "SERVICE AREA": null,
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "Cash | Credit Card | Debit Card",
      "NOTES": "Same day appointments based on availability ",
      "latitude": null,
      "longitude": null
  },
  {
      "COMPANY": "Moses Lake Taxi & Delivery",
      "ADDRESS": null,
      "CAPABILITY": "Taxi",
      "PHONE": "509-707-0219",
      "HOURS": "Depending on availability",
      "Population Served": "All",
      "SERVICES": "Hospital Pick-up",
      "SERVICE AREA": null,
      "OXYGEN": "Ok if independent with use",
      "Pay Source": "Cash | Credit Card | Debit Card",
      "NOTES": "No assistance to door. \nNeeds to be independent with mobility. ",
      "latitude": null,
      "longitude": null
  }
]

// Fix the default icon issue in Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Injury_icon_2.svg/300px-Injury_icon_2.svg.png',
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Injury_icon_2.svg/300px-Injury_icon_2.svg.png',
  iconSize: [25, 25],
  shadowSize: [0, 0],
  iconAnchor: [0,0]
});


const transportIcon = L.icon({
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Blue_ambulance_icon.svg/2560px-Blue_ambulance_icon.svg.png',//'https://cdn-icons-png.flaticon.com/512/2920/2920297.png', // Replace with your transport icon URL
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});


const HospitalMap = () => {
  const [hospitals, setHospitals] = useState([]);
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [availableServices, setAvailableServices] = useState([]); // Dynamic filters
  const [filteredServices, setFilteredServices] = useState([]); // For searchable services
  const [selectedServices, setSelectedServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // Search bar query

  useEffect(() => {
    // Fetch hospital locations from API
    fetch('https://jbh-fhir.azurewebsites.net/api/hospitallocation')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch hospital data');
        }
        return response.json();
      })
      .then(data => {
        const formattedData = data.map(hospital => ({
          id: hospital.id,
          name: hospital.name,
          address: `${hospital.address}, ${hospital.city}, ${hospital.state}`,
          lat: hospital.latitude,
          lng: hospital.longitude,
          services: hospital.services || [],
        }));

        // Extract unique services dynamically and sort them alphabetically
        const allServices = formattedData.flatMap(hospital => hospital.services.map(s => s.name));
        const uniqueServices = Array.from(new Set(allServices)).sort();

        setAvailableServices(uniqueServices);
        setFilteredServices(uniqueServices); // Initialize searchable services
        setHospitals(formattedData);
        setFilteredHospitals(formattedData);
      })
      .catch(error => console.error('Error fetching hospital data:', error));
  }, []);

  const handleServiceChange = (service) => {
    setSelectedServices(prevState =>
      prevState.includes(service)
        ? prevState.filter(s => s !== service)
        : [...prevState, service]
    );
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter available services based on search input
    const filtered = availableServices.filter(service =>
      service.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  useEffect(() => {
    if (selectedServices.length === 0) {
      setFilteredHospitals(hospitals);
    } else {
      setFilteredHospitals(
        hospitals.filter(hospital =>
          selectedServices.every(service =>
            hospital.services.some(s => s.name === service)
          )
        )
      );
    }
  }, [selectedServices, hospitals]);

  return (
    <div className="map-container" style={{ display: 'flex' }}>
      {/* Sidebar for filters */}
      <div
        className="sidebar"
        style={{
          width: '300px',
          padding: '10px',
          backgroundColor: '#f9f9f9',
          overflowY: 'auto',
          maxHeight: '80vh',
        }}
      >
        <h3>Filter by Services</h3>
        
        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search services..."
          value={searchQuery}
          onChange={handleSearchChange}
          style={{
            width: '100%',
            marginBottom: '10px',
            padding: '5px',
            boxSizing: 'border-box',
          }}
        />

        {/* Filter List */}
        {filteredServices.map(service => (
          <div key={service}>
            <input
              type="checkbox"
              id={service}
              value={service}
              checked={selectedServices.includes(service)}
              onChange={() => handleServiceChange(service)}
            />
            <label htmlFor={service}>{service}</label>
          </div>
        ))}
      </div>

      {/* Map Container */}
      <MapContainer center={[47.751076, -120.740135]} zoom={7} style={{ height: '80vh', flex: 1 }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {filteredHospitals.map(hospital => (
          <Marker key={hospital.id} position={[hospital.lat, hospital.lng]}>
            <Popup>
              <b>{hospital.name}</b>
              <br />
              {hospital.address}
            </Popup>
          </Marker>
        ))}

  {transportData.filter(a => a.ADDRESS != null).map(tran => (
          <Marker key={tran.id} position={[tran.latitude, tran.longitude]}  icon={transportIcon}>
            <Popup>
              <b>{tran.COMPANY}</b>
              <br />
              {tran.ADDRESS}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default HospitalMap;
