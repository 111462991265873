import * as React from 'react';


function Coltrain(props) {
  return (
    <div className='coltrain-pane'>

    </div>
  );
}

export default Coltrain